import { useMutation, useQuery } from '@apollo/client';
import Layout from '@layouts';
import { getErrorMessages, getInvalidFields, getQueryName } from '@lib';
import { useFormik } from 'formik';
import { PageProps } from 'gatsby';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { toast } from 'react-hot-toast';

import {
  GetUserOnBillingPageDocument,
  GetUserOnInterestsPageDocument,
  UpdateInterestsDocument,
} from '@redsleeve/oilynx-domain';

import Button from '@components/Button';
import CategoryCard from '@components/category/CategoryCard';
import UserStatusBadge from '@components/user/UserStatusBadge';
import { UserStatusType } from '@components/user/domain';

import { formatCurrency, formatDate, formatTimeUntilNow } from '@lib/format';
import { useCategories } from '@lib/hooks/useOrderedCategories';

import Menu from './Menu';

const InterestsPage: FC<PageProps> = ({ location }) => {
  const { groupedCategories } = useCategories();

  const billingInformationQuery = useQuery(GetUserOnBillingPageDocument);
  const interestsQuery = useQuery(GetUserOnInterestsPageDocument);
  const [updateInterests] = useMutation(UpdateInterestsDocument, {
    refetchQueries: [getQueryName(GetUserOnInterestsPageDocument)],
  });

  const formik = useFormik({
    initialValues: {
      interests: [],
    },
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      const toastId = toast.loading('Updating interests...');

      try {
        await updateInterests({
          variables: {
            categoryIds: values.interests,
          },
        });

        toast.success('Interests updated!', { id: toastId });
      } catch (ex) {
        const formError = getInvalidFields(ex);
        if (formError) setErrors(formError);
        const errorMessages = getErrorMessages(ex);
        toast.error(errorMessages?.join('\n') ?? 'Failed to update interests', {
          id: toastId,
          duration: 10 * 1000,
        });
        if (!errorMessages) console.error('[InterestsPage] onSubmit', ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (!interestsQuery.data) return;
    formik.setValues({ interests: interestsQuery.data.currentUser.interests });
  }, [interestsQuery.data]);

  function willPriceBeDifferent() {
    const subscriptionDetails =
      interestsQuery.data?.currentUser?.subscriptionDetails;
    if (!subscriptionDetails) return false;
    return subscriptionDetails.quantity !== formik.values.interests.length;
  }

  function renderSummary() {
    const subscriptionDetails =
      interestsQuery.data?.currentUser?.subscriptionDetails;
    if (!subscriptionDetails)
      return <div className="">You are not subscribed to any category.</div>;
    return (
      <>
        <div className="">
          Current: {subscriptionDetails.quantity} x{' '}
          {formatCurrency(subscriptionDetails.unitPrice / 100, 'CHF')} ={' '}
          {formatCurrency(
            (subscriptionDetails.quantity * subscriptionDetails.unitPrice) /
              100,
            'CHF'
          )}
        </div>
        {willPriceBeDifferent() && (
          <div className="">
            After save: {formik.values.interests.length} x{' '}
            {formatCurrency(subscriptionDetails.unitPrice / 100, 'CHF')} ={' '}
            {formatCurrency(
              (formik.values.interests.length * subscriptionDetails.unitPrice) /
                100,
              'CHF'
            )}
          </div>
        )}
      </>
    );
  }

  function renderStatus() {
    if (billingInformationQuery.loading) return null;

    const details =
      billingInformationQuery.data?.currentUser?.subscriptionDetails;

    let message = undefined;
    if (!details)
      message =
        "You don't have an active subscription. Please select and interest to start.";
    else if (details.status === 'trialing')
      message = `Trial ends ${formatTimeUntilNow(
        details.currentPeriodEnd * 1000
      )}. First invoice will be sent on ${formatDate(
        details.currentPeriodEnd * 1000
      )}.`;
    // else if (details.status === 'past_due')
    //   message = `Your account is disabled because the last invoice was not paid.`;

    if (message) return <p className="text-sm font-emp">{message}</p>;

    return (
      <UserStatusBadge
        className="max-w-20"
        status={
          UserStatusType[
            (Object.keys(UserStatusType).find(
              (item) =>
                item ===
                billingInformationQuery.data?.currentUser?.subscriptionDetails
                  ?.status
            ) || 'INVALID_SUBSCRIPTION') as keyof typeof UserStatusType
          ]
        }
      />
    );
  }

  return (
    <Layout url="/profile/interests/" title="Interests settings" disableIndex>
      <Menu currentPath={location.pathname}>
        <form
          onSubmit={formik.handleSubmit}
          className="flex-1 flex flex-col mb-0"
        >
          <div className="lg:pl-12 flex-1 flex flex-col">
            <h3 className="hidden lg:block font-emp text-2xl">Interests</h3>
            <p className="text-sm opacity-70">
              Manage your categories of interest
            </p>

            {/*{renderStatus()}*/}

            <div className="flex space-x-6 overflow-x-auto py-4 max-w-full">
              {groupedCategories.map((cat) => (
                <CategoryCard key={cat.id} cat={cat} formik={formik} />
              ))}
            </div>

            {/*<h3 className="font-emp text-2xl mt-12">Pricing status</h3>*/}
            {/*{renderSummary()}*/}

            {/*<p className="mt-12 font-emp text-sm">*/}
            {/*  Changes you make will be sent out on next month's invoice but will*/}
            {/*  immediately be visible in the Trades map and table.*/}
            {/*</p>*/}

            <div className="flex-1" />
            <div className="p-4 -mx-4 mt-8 lg:mt-20 lg:bg-transparent">
              <Button
                className="w-full"
                type="submit"
                variant="primary"
                disabled={formik.isSubmitting}
              >
                Save Interests
              </Button>
            </div>
          </div>
        </form>
      </Menu>
    </Layout>
  );
};

export default InterestsPage;
